import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { transformSelectOptionValue } from 'helpers'
import { useEffect } from 'react'
import Guru from 'toolComponents/guru'
import { ButtonGroup, Input, ModelSelector, ReactSelect } from 'ui'
import { CheckForInputData, Creativity } from '../writer-options-data'
import aiController from 'helpers/AIController'

export default function CheckTextOptions({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setMyText,
  setCreativity,
  creativity,
  optionsData,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  model,
  setModel,
}) {
  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const isPanelLarge = usePanelSize('check-text')
  const { t } = useTranslate()

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  return (
    visible && (
      <div
        className={classNames(
          'flex flex-col gap-[16px] break-words',
          isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
        )}
      >
        <div
          id='my-text'
          className='flex w-full items-start gap-[12px]'
          data-tooltip-id='writer-checkText-myText'
        >
          <FieldDescription>
            <T keyName='eleo-tool-modify-text-my-text'>My Text</T>
          </FieldDescription>
          <Input
            className='placeholder-font-regular min-h-[480px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
            onChange={setMyText}
            value={optionsData.myText}
            placeholder={t('eleo-tool-check-text-my-text-placeholder', 'Start typing here')}
            isTextArea
            resize='none'
          />
        </div>
        <div className='flex w-full flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
          <div
            id='what-to-check'
            className='flex w-full  items-center gap-[10px] min-[1200px]:w-1/2'
            data-tooltip-id='writer-checkText-checkFor'
          >
            <FieldDescription>
              <T keyName='eleo-tool-check-text-check-for'>Check For</T>
            </FieldDescription>
            <ReactSelect
              options={CheckForInputData}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(
                CheckForInputData,
                optionsData.state.check,
                CheckForInputData[0]
              )}
              setSelectedValue={handleTopicChange}
              name='CHECK'
              menuPlacement='top'
            />
          </div>
          <div
            id='language'
            className='flex w-full  items-center gap-[10px] min-[1200px]:w-1/2'
            data-tooltip-id='writer-checkText-language'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-language'>Language</T>
            </FieldDescription>
            <ReactSelect
              options={languages}
              setSelectedValue={handleTopicChange}
              value={transformSelectOptionValue(
                languages,
                optionsData.state.language,
                defaultToolOutPutLanguage
              )}
              name='LANGUAGE'
              menuPlacement='top'
              menuAlignment='right'
            />
          </div>
        </div>
        <div className='flex items-center gap-[10px]'>
          <div
            id='creativity'
            data-tooltip-id='writer-checkText-creativity'
            className='flex items-center gap-[10px]'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </FieldDescription>
            <div className='w-full'>
              <ButtonGroup
                options={Creativity}
                onClick={(event) => setCreativity(event.target.value)}
                value={creativity}
                activeBgClass='bg-[#6363e51a]'
                className='w-full'
              />
            </div>
          </div>
          <div className='flex justify-end'>
            <ModelSelector
              id='model-selector'
              models={aiController.getSupportedTextModels()}
              model={model}
              handleModelChange={(model) => setModel({ ...model, description: undefined })}
              translations={{
                buttonLabel: t('eleo-chat-settings-model', 'Model'),
                noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),
                defaultModel: t('eleo-chat-settings-model-default', 'dafault'),
                popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
                popupDescription: (
                  <T
                    keyName='eleo-chat-settings-model-popup-description'
                    params={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://news.eleo.ai/which-language-model-to-choose'
                          className='text-brand-violet font-medium opacity-100'
                        />
                      ),
                      span: <span className='opacity-70' />,
                    }}
                    defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
                  ></T>
                ),
              }}
              origin='bottom'
            />
          </div>
        </div>
        {/* <div className='flex !w-full items-center justify-between gap-[10px]'>
          <Guru
            tool='writer-checkText-guru'
            className='w-full justify-between'
            place='top'
            setIsGuruModeEnabled={setIsGuruModeEnabled}
            isGuruModeEnabled={isGuruModeEnabled}
          />
        </div> */}
      </div>
    )
  )
}
