import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { transformSelectOptionValue } from 'helpers'
import Guru from 'toolComponents/guru'
import MoreOptions from 'toolComponents/more-options'
import { Input, ModelSelector, Range, ReactSelect } from 'ui'
import WriterLengthContainer from '../writer/WriterLengthContainer'
import { ToneInputData } from '../writer/writer-options-data'
import { IdeaFor } from './great-ideas-options-data'
import aiController from 'helpers/AIController'

export default function GreatIdeasOptions({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setWriterLengthOption,
  setText,
  setDetails,
  setNegativeWords,
  wordLength,
  writerLengthOption,
  setCreativity,
  optionsData,
  creativity,
  isShowAdvanced,
  setIsShowAdvanced,
  setIsGuruModeEnabled,
  isGuruModeEnabled,
  documentContext,
  model,
  setModel,
}) {
  const isPanelLarge = usePanelSize('ideas')
  const { t } = useTranslate()

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const handleRadioButtonCheck = ({ radioBoxName, checked }) => {
    setWordLength({ id: radioBoxName, checked })
  }

  return (
    visible && (
      <div
        className={classNames(
          'flex flex-col gap-[16px] break-words',
          isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
        )}
      >
        <div className='!w-full'>
          <ContextField documentContext={documentContext} />
        </div>
        <div
          id='input'
          className='flex w-full items-start gap-[12px]'
          data-tooltip-id='greatIdeas-describe'
        >
          <FieldDescription>
            <T keyName='eleo-tool-great-ideas-describe-what-are-you-looking-for'>
              Describe what are you looking for
            </T>
          </FieldDescription>
          <Input
            placeholder={t('eleo-tool-great-ideas-describe-placeholder', 'Describe')}
            className='placeholder-font-regular min-h-[80px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
            onChange={setText}
            value={optionsData.text}
            isTextArea
          />
        </div>
        <div className='flex w-full items-start gap-[12px]' data-tooltip-id='greatIdeas-details'>
          <FieldDescription>
            <T keyName='eleo-tool-great-ideas-details'>Details</T>
          </FieldDescription>
          <Input
            placeholder={t('eleo-tool-great-ideas-details-placeholder', 'Details')}
            className='placeholder-font-regular min-h-[100px] w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={optionsData.details}
            onChange={setDetails}
            isTextArea
          />
        </div>
        <div className='flex w-full items-start gap-[12px]' data-tooltip-id='greatIdeas-negative'>
          <FieldDescription>
            <T keyName='eleo-tool-great-ideas-negative-words'>
              Describe what you don’t want to read
            </T>
          </FieldDescription>
          <Input
            placeholder={t(
              'eleo-tool-great-ideas-negative-words-placeholder',
              'Describe what you don’t want to read'
            )}
            className='placeholder-font-regular min-h-[100px] w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={optionsData.negativeWords}
            onChange={setNegativeWords}
            isTextArea
          />
        </div>
        <div className='flex w-full flex-wrap items-center gap-[12px] min-[1400px]:flex-nowrap'>
          <div
            className='flex w-full   items-center gap-[10px] min-[1400px]:w-1/2'
            data-tooltip-id='greatIdeas-ideaFor'
          >
            <FieldDescription>
              <T keyName='eleo-tool-great-ideas-idea-for'>Idea For</T>
            </FieldDescription>
            <ReactSelect
              options={IdeaFor}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(IdeaFor, optionsData.state.ideaFor, null)}
              setSelectedValue={handleTopicChange}
              name='IDEA'
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
            />
          </div>
          <div className='flex justify-end'>
            <ModelSelector
              id='model-selector'
              models={aiController.getSupportedTextModels()}
              model={model}
              handleModelChange={(model) => setModel({ ...model, description: undefined })}
              translations={{
                buttonLabel: t('eleo-chat-settings-model', 'Model'),
                noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),
                defaultModel: t('eleo-chat-settings-model-default', 'dafault'),
                popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
                popupDescription: (
                  <T
                    keyName='eleo-chat-settings-model-popup-description'
                    params={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://news.eleo.ai/which-language-model-to-choose'
                          className='text-brand-violet font-medium opacity-100'
                        />
                      ),
                      span: <span className='opacity-70' />,
                    }}
                    defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
                  ></T>
                ),
              }}
              origin='bottom'
            />
          </div>
          {/* <div
            id='story'
            className={classNames('flex w-full  items-center gap-[10px] min-[1400px]:w-1/2')}
            data-tooltip-id='greatIdeas-myStory'
          >
            <FieldDescription>
              <T keyName='eleo-your-story'>Your story</T>
            </FieldDescription>
            <ReactSelect
              options={storyTemplateOptions}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              // disabled={!allowStandard}
              // defaultValue={options[0]}
              setSelectedValue={setStoryId}
              value={storyId.value ? storyId : null}
              name='STORY'
              menuAlignment='right'
              action={() => navigate('/account/your-story')}
              actionLabel={t('eleo-add', 'Add')}
              menuMinWidth={200}
            />
          </div> */}
        </div>
        {/* Show advanced button */}
        <div className='flex items-center justify-center text-[14px] '>
          <button
            className={classNames(
              'flex w-auto items-center justify-center rounded-[4px] text-white',
              isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
            )}
            onClick={() => setIsShowAdvanced((prev) => !prev)}
          >
            <div className='ml-[5px]'>
              <MoreOptions isShowAdvanced={isShowAdvanced} />
            </div>
            {isShowAdvanced ? (
              <ChevronLeft className='rotate-90' />
            ) : (
              <ChevronRight className='rotate-90' />
            )}
          </button>
        </div>
        {/* Advanced section */}
        <div
          className={classNames(
            'flex flex-col gap-[16px]',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
          )}
        >
          <div className='flex w-full flex-wrap items-center gap-[12px] min-[1400px]:flex-nowrap'>
            <div
              className='flex w-full  items-center gap-[10px] min-[1400px]:w-1/2'
              data-tooltip-id='greatIdeas-tone'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-tone'>Tone</T>
              </FieldDescription>
              <ReactSelect
                options={ToneInputData}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                // defaultValue={options[0]}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(ToneInputData, optionsData.state.tone, null)}
                name='TONE'
                menuPlacement='top'
              />
            </div>
            <div
              className='flex w-full items-center gap-[10px] min-[1400px]:w-1/2'
              data-tooltip-id='greatIdeas-language'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-language'>Language</T>
              </FieldDescription>
              <ReactSelect
                options={languages}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(
                  languages,
                  optionsData.state.language,
                  defaultToolOutPutLanguage
                )}
                name='LANGUAGE'
                menuPlacement='top'
                menuAlignment='right'
              />
            </div>
          </div>
          <div
            id='creativity'
            className='flex w-full items-center gap-[10px]'
            data-tooltip-id='greatIdeas-creativity'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </FieldDescription>
            <div className='!w-full'>
              <Range min={1} max={7} onChange={setCreativity} value={creativity} />
              <div className='text-brand-secondary flex justify-between text-sm'>
                <div>
                  <T keyName='eleo-tool-great-ideas-creativity-literal'>Literal</T>
                </div>
                <div>
                  <T keyName='eleo-tool-great-ideas-creativity-creative'>Creative</T>
                </div>
              </div>
            </div>
          </div>
          <div className='flex w-full items-center gap-[12px]' data-tooltip-id='greatIdeas-length'>
            <FieldDescription>
              <T keyName='eleo-write-text-length'>Length</T>
            </FieldDescription>
            <WriterLengthContainer
              handleRadioButtonCheck={handleRadioButtonCheck}
              selectedWordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              forceMobile={!isPanelLarge}
            />
          </div>

          {/* <div className='flex justify-between pb-[15px]'>
            <Guru
              tool='greatIdeas-guru'
              className='!w-full justify-between'
              place='top'
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              isGuruModeEnabled={isGuruModeEnabled}
            />
          </div> */}
        </div>
      </div>
    )
  )
}
