import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useAccess } from 'components/hooks/access'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { useWindowDimensions } from 'components/hooks/window'
import { transformReadyMadeTemplatesData, transformSelectOptionValue } from 'helpers'
import Guru from 'toolComponents/guru'
import MoreOptions from 'toolComponents/more-options'
import { ButtonGroup, CheckBox, Input, ModelSelector, ReactSelect, Toggle } from 'ui'
import WriterLengthContainer from '../WriterLengthContainer'
import {
  AuthorInputData,
  Creativity,
  FormatInputData,
  ToneInputData,
  WritingStyleInputData,
  checkboxData,
} from '../writer-options-data'
import { readyMadeTemplates } from '../writer-options-data/ready-made-templates'
import aiController from 'helpers/AIController'

export default function WriterOptions({
  visible,
  dispatch,
  actionTypes,
  checkBoxValues,
  setCheckBoxValues,
  setWordLength,
  setTopic,
  setWriterContext,
  setKeyWords,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  setCreativity,
  creativity,
  isOptimizedForSeoEnabled,
  setIsOptimizedForSeoEnabled,
  optionsData,
  templateOptions,
  setTemplateId,
  showUpgradePlanModal,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  contextPlaceHolder,
  topicPlaceHolder,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  handleClearTemplate,
  documentContext,
  model,
  setModel,
}) {
  const { height } = useWindowDimensions()
  const { t } = useTranslate()
  const isPanelLarge = usePanelSize('writer')

  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const readyMadeTemplatesData = transformReadyMadeTemplatesData(readyMadeTemplates, t)

  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const handleCheckBoxValues = ({ checkBoxName, checked }) => {
    const existingItem = checkBoxValues?.find((item) => item.name === checkBoxName)

    if (!existingItem) {
      setCheckBoxValues((prev) => [...prev, { name: checkBoxName, checked }])
    } else {
      setCheckBoxValues((items) => {
        const values = [...items]
        return values.filter((item) => item.name !== checkBoxName)
      })
    }
  }

  const handleRadioButtonCheck = ({ radioBoxName, checked }) => {
    setWordLength({ id: radioBoxName, checked })
  }

  const handleSelectTemplate = (e) => {
    if (!allowStandard) return showUpgradePlanModal()

    setTemplateId(e)
  }

  return (
    visible && (
      <div
        className={classNames(
          'relative flex flex-col break-words pb-6',
          isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
        )}
        style={{ gap: height <= 768 ? '14px' : '16px' }}
      >
        <div className='!w-full'>
          <ContextField documentContext={documentContext} />
        </div>
        <div className='flex w-full flex-wrap items-center justify-between gap-[12px] min-[1200px]:flex-nowrap'>
          <div
            className={classNames('flex w-full items-center gap-[10px] min-[1200px]:w-1/2')}
            data-tooltip-id='writer-writeText-template'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-template'>Template</T>
            </FieldDescription>
            <ReactSelect
              id='template'
              options={templateOptions}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              customOptions={readyMadeTemplatesData}
              // defaultValue={options[0]}
              setSelectedValue={handleSelectTemplate}
              value={templateId.value ? templateId : null}
              showDeleteIcon
              deleteAction={(templateId) => handleDeleteTemplate(templateId)}
              name='TEMPLATE'
              onClear={handleClearTemplate}
            />
          </div>

          <div className='flex justify-end'>
            <ModelSelector
              id='model-selector'
              models={aiController.getSupportedTextModels()}
              model={model}
              handleModelChange={(model) => setModel({ ...model, description: undefined })}
              translations={{
                buttonLabel: t('eleo-chat-settings-model', 'Model'),
                noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),
                defaultModel: t('eleo-chat-settings-model-default', 'dafault'),
                popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
                popupDescription: (
                  <T
                    keyName='eleo-chat-settings-model-popup-description'
                    params={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://news.eleo.ai/which-language-model-to-choose'
                          className='text-brand-violet font-medium opacity-100'
                        />
                      ),
                      span: <span className='opacity-70' />,
                    }}
                    defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
                  ></T>
                ),
              }}
              origin='bottom'
            />
          </div>
          {/* <div
            className={classNames('flex w-full  items-center gap-[10px] min-[1200px]:w-1/2')}
            data-tooltip-id='writer-writeText-myStory'
          >
            <FieldDescription>
              <T keyName='eleo-your-story'>Your story</T>
            </FieldDescription>
            <ReactSelect
              id='your-story'
              options={storyTemplateOptions}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              // disabled={!allowStandard}
              // defaultValue={options[0]}
              setSelectedValue={setStoryId}
              value={storyId.value ? storyId : null}
              name='STORY'
              menuAlignment='right'
              action={() => navigate('/account/your-story')}
              actionLabel={t('eleo-add', 'Add')}
              menuMinWidth={200}
            />
          </div> */}
        </div>
        <div
          className='flex w-full items-center gap-[12px]'
          data-tooltip-id='writer-writeText-topic'
        >
          <FieldDescription>
            <T keyName='eleo-write-text-topic'>Topic</T>
          </FieldDescription>
          <Input
            id='topic'
            placeholder={
              topicPlaceHolder.value
                ? t(topicPlaceHolder.tolgee_id, topicPlaceHolder.value)
                : t(
                    'eleo-write-text-topic-placeholder',
                    'For Example: How AI can change the world?'
                  )
            }
            className='placeholder-font-regular w-full text-[13px] placeholder-[#36363680]'
            onChange={setTopic}
            value={optionsData.topic}
          />
        </div>
        <div
          className='flex w-full items-start gap-[12px]'
          data-tooltip-id='writer-writeText-context'
        >
          <FieldDescription>
            <T keyName='eleo-write-text-context'>Description</T>
          </FieldDescription>
          <Input
            placeholder={
              contextPlaceHolder.value
                ? t(contextPlaceHolder.tolgee_id, contextPlaceHolder.value)
                : t('eleo-write-text-context-placeholder', 'Provide more details about your text')
            }
            className='placeholder-font-regular min-h-[85px] w-full rounded-[8px] text-[13px] placeholder-[#36363680] min-[1370px]:min-h-[100px]'
            onChange={setWriterContext}
            value={optionsData.writerContext}
            isTextArea
          />
        </div>

        <section
          className={classNames(
            'writer-tour-options flex !w-full flex-col gap-[12px]',
            isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
          )}
        >
          <div
            className={classNames(
              'flex w-full flex-wrap items-center gap-[12px]',
              isPanelLarge && '!flex-nowrap'
            )}
          >
            <div
              className='flex w-full items-center gap-[10px] min-[1400px]:w-1/2'
              data-tooltip-id='writer-writeText-format'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-format'>Format</T>
              </FieldDescription>
              <ReactSelect
                options={FormatInputData}
                // defaultValue={options[0]}
                setSelectedValue={handleTopicChange}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                value={transformSelectOptionValue(FormatInputData, optionsData.state.format, null)}
                name='FORMAT'
                menuMinWidth={250}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
              />
            </div>
            <div
              className={classNames('flex w-full  items-center gap-[10px] min-[1400px]:w-1/2')}
              data-tooltip-id='writer-writeText-writingStyle'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-style'>Style</T>
              </FieldDescription>
              <ReactSelect
                options={WritingStyleInputData}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
                // defaultValue={options[0]}
                setSelectedValue={handleTopicChange}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                value={transformSelectOptionValue(
                  WritingStyleInputData,
                  optionsData.state.style,
                  null
                )}
                name='STYLE'
                menuAlignment='right'
                menuMinWidth={350}
              />
            </div>
          </div>
          {/* Show advanced button */}
          <div className='flex items-center justify-center text-[14px] '>
            <button
              className={classNames(
                'flex w-auto items-center justify-center rounded-[4px] text-white',
                isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
              )}
              onClick={() => setIsShowAdvanced((prev) => !prev)}
            >
              <div className='ml-[5px]'>
                <MoreOptions isShowAdvanced={isShowAdvanced} />
              </div>
              {isShowAdvanced ? (
                <ChevronLeft className='rotate-90' />
              ) : (
                <ChevronRight className='rotate-90' />
              )}
            </button>
          </div>
        </section>
        {/* Advanced section */}
        <div
          className={classNames(
            'flex flex-col',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
          )}
          style={{ gap: height <= 768 ? '14px' : '16px' }}
        >
          <div
            className='flex w-full items-center gap-[12px]'
            data-tooltip-id='writer-writeText-keywords'
          >
            <FieldDescription>
              <T keyName='eleo-write-seo-keywords'>SEO Keywords</T>
            </FieldDescription>
            <div
              className={classNames('flex w-full items-center gap-x-2', isPanelLarge && 'flex-1')}
            >
              <Toggle
                isChecked={isOptimizedForSeoEnabled}
                setIsChecked={setIsOptimizedForSeoEnabled}
              />
              <Input
                placeholder={t('eleo-write-seo-keywords-placeholder', 'Your SEO keywords')}
                className={classNames(
                  'placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]',
                  !isOptimizedForSeoEnabled && 'text-brand-secondary !bg-gray-100'
                )}
                disabled={!isOptimizedForSeoEnabled}
                value={optionsData.keyWords}
                onChange={setKeyWords}
              />
            </div>
          </div>
          <div
            className='flex w-full items-center gap-[12px]'
            data-tooltip-id='writer-writeText-length'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-length'>Length</T>
            </FieldDescription>
            <WriterLengthContainer
              handleRadioButtonCheck={handleRadioButtonCheck}
              selectedWordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              forceMobile={!isPanelLarge}
            />
          </div>
          <div
            className='flex w-full items-center gap-[12px]'
            data-tooltip-id='writer-writeText-include'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-include'>Include</T>
            </FieldDescription>
            <div className='flex !w-full flex-wrap gap-[10px]'>
              {checkboxData.map((checkbox, i) => (
                <CheckBox
                  key={i}
                  checkBoxName={checkbox.name}
                  id={checkbox.name}
                  checkBoxValue={
                    optionsData.checkBoxValues.find((item) => item.name === checkbox.name)
                      ?.checked || false
                  }
                  size={15}
                  labelClasses='text-[13px]'
                  setChecked={handleCheckBoxValues}
                  label={<T keyName={`${checkbox.tolgee_id}`}>{checkbox.title}</T>}
                />
              ))}
            </div>
          </div>
          <div
            className='flex !w-full items-center gap-[10px]'
            data-tooltip-id='writer-writeText-creativity'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </FieldDescription>
            <div className='w-full'>
              <ButtonGroup
                options={Creativity}
                onClick={(event) => setCreativity(event.target.value)}
                value={creativity}
                activeBgClass='bg-[#6363e51a]'
                className='!flex flex-shrink'
              />
            </div>
          </div>
          <div className='flex flex-wrap items-center justify-between gap-[12px] min-[1200px]:flex-nowrap'>
            <div
              className='flex w-full items-center gap-[10px] min-[1200px]:w-1/2'
              data-tooltip-id='writer-writeText-author'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-author'>Author</T>
              </FieldDescription>
              <ReactSelect
                options={AuthorInputData}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
                // defaultValue={options[0]}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(AuthorInputData, optionsData.state.author, null)}
                name='AUTHOR'
                menuPlacement='top'
              />
            </div>
            <div
              className='flex w-full  items-center gap-[10px] min-[1200px]:w-1/2'
              data-tooltip-id='writer-writeText-language'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-language'>Language</T>
              </FieldDescription>
              <ReactSelect
                options={languages}
                setSelectedValue={handleTopicChange}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                value={transformSelectOptionValue(
                  languages,
                  optionsData.state.language,
                  defaultToolOutPutLanguage
                )}
                name='LANGUAGE'
                menuPlacement='top'
                menuAlignment='right'
              />
            </div>
          </div>
          <div
            className='flex w-full !flex-nowrap items-center gap-[10px]'
            data-tooltip-id='writer-writeText-tone'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-tone'>Tone</T>
            </FieldDescription>
            <div className='!w-full'>
              <ReactSelect
                options={ToneInputData}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
                // defaultValue={options[0]}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(ToneInputData, optionsData.state.tone, null)}
                name='TONE'
                menuPlacement='top'
              />
            </div>
          </div>
          {/* <div className='flex !w-full flex-wrap items-center justify-between gap-[12px]'>
            <Guru
              tool='writer-writeText-guru'
              className={classNames(!isPanelLarge && 'justify-between')}
              place='top'
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              isGuruModeEnabled={isGuruModeEnabled}
            />
           
          </div> */}
        </div>
      </div>
    )
  )
}
