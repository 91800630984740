import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { transformReadyMadeTemplatesData, transformSelectOptionValue } from 'helpers'
import Guru from 'toolComponents/guru'
import MoreOptions from 'toolComponents/more-options'
import { ButtonGroup, Input, ModelSelector, ReactSelect, Toggle } from 'ui'
import WriterLengthContainer from '../WriterLengthContainer'
import {
  ActionsInputData,
  AuthorInputData,
  Creativity,
  ToneInputData,
  WritingStyleInputData,
} from '../writer-options-data'
import { modifyTextReadyMadeTemplates } from '../writer-options-data/ready-made-templates'
import aiController from 'helpers/AIController'

export default function ModifyTextOptions({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setMyText,
  setWhatToDo,
  setKeyWords,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  setCreativity,
  creativity,
  optionsData,
  templateOptions,
  setTemplateId,
  allowStandard,
  showUpgradePlanModal,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  isOptimizedForSeoEnabled,
  setIsOptimizedForSeoEnabled,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  documentContext,
  model,
  setModel,
}) {
  const isPanelLarge = usePanelSize('modify-text')
  const { t } = useTranslate()

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const readyMadeTemplatesData = transformReadyMadeTemplatesData(modifyTextReadyMadeTemplates, t)

  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const handleRadioButtonCheck = ({ radioBoxName, checked }) => {
    setWordLength({ id: radioBoxName, checked })
  }

  const handleSelectTemplate = (e) => {
    if (!allowStandard) return showUpgradePlanModal()

    setTemplateId(e)
  }

  return (
    visible && (
      <div
        className={classNames(
          'flex flex-col gap-[16px] break-words',
          isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
        )}
      >
        <div className='!w-full'>
          <ContextField documentContext={documentContext} />
        </div>
        <div className='flex w-full flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
          <div
            className={classNames('flex w-full  items-center gap-[10px] min-[1200px]:w-1/2')}
            data-tooltip-id='writer-modifyText-template'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-template'>Template</T>
            </FieldDescription>
            <ReactSelect
              options={templateOptions}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              customOptions={readyMadeTemplatesData}
              // defaultValue={options[0]}
              setSelectedValue={handleSelectTemplate}
              value={templateId.value ? templateId : null}
              showDeleteIcon
              deleteAction={(templateId) => handleDeleteTemplate(templateId)}
              name='TEMPLATE'
            />
          </div>
          <div className='flex justify-end'>
            <ModelSelector
              id='model-selector'
              models={aiController.getSupportedTextModels()}
              model={model}
              handleModelChange={(model) => setModel({ ...model, description: undefined })}
              translations={{
                buttonLabel: t('eleo-chat-settings-model', 'Model'),
                noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),
                defaultModel: t('eleo-chat-settings-model-default', 'dafault'),
                popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
                popupDescription: (
                  <T
                    keyName='eleo-chat-settings-model-popup-description'
                    params={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://news.eleo.ai/which-language-model-to-choose'
                          className='text-brand-violet font-medium opacity-100'
                        />
                      ),
                      span: <span className='opacity-70' />,
                    }}
                    defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
                  ></T>
                ),
              }}
              origin='bottom'
            />
          </div>
          {/* <div
            id='your-story'
            className={classNames('flex w-full  items-center gap-[10px] min-[1200px]:w-1/2')}
            data-tooltip-id='writer-modifyText-myStory'
          >
            <FieldDescription>
              <T keyName='eleo-your-story'>Your story</T>
            </FieldDescription>
            <ReactSelect
              options={storyTemplateOptions}
              isClearable
              clearLabel={t('eleo-clear-button-label-none', 'None')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              // disabled={!allowStandard}
              // defaultValue={options[0]}
              setSelectedValue={setStoryId}
              value={storyId.value ? storyId : null}
              name='STORY'
              menuAlignment='right'
              action={() => navigate('/account/your-story')}
              actionLabel={t('eleo-add', 'Add')}
              menuMinWidth={200}
            />
          </div> */}
        </div>
        <div
          id='my-text'
          className='flex w-full items-start gap-[12px]'
          data-tooltip-id='writer-modifyText-myText'
        >
          <FieldDescription>
            <T keyName='eleo-tool-modify-text-my-text'>My Text</T>
          </FieldDescription>
          <Input
            placeholder={t(
              'eleo-tool-modify-text-my-text-placeholder',
              'Provide more details about your text'
            )}
            className='placeholder-font-regular min-h-[190px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
            onChange={setMyText}
            value={optionsData.myText}
            isTextArea
          />
        </div>
        <div
          id='what-to-do'
          className='flex w-full items-center gap-[12px]'
          data-tooltip-id='writer-modifyText-whatToDo'
        >
          <FieldDescription>
            <T keyName='eleo-tool-modify-text-what-should-i-do'>What should I do?</T>
          </FieldDescription>
          <Input
            placeholder={t(
              'eleo-tool-modify-text-what-should-i-do-placeholder',
              'What should I do with this text?'
            )}
            className='placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={optionsData.whatToDo}
            onChange={setWhatToDo}
          />
        </div>

        {/* Show advanced button */}
        <div className='flex items-center justify-center text-[14px] '>
          <button
            className={classNames(
              'flex w-auto items-center justify-center rounded-[4px] text-white',
              isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
            )}
            onClick={() => setIsShowAdvanced((prev) => !prev)}
          >
            <div className='ml-[5px]'>
              <MoreOptions isShowAdvanced={isShowAdvanced} />
            </div>
            {isShowAdvanced ? (
              <ChevronLeft className='rotate-90' />
            ) : (
              <ChevronRight className='rotate-90' />
            )}
          </button>
        </div>
        {/* Advanced section */}
        <div
          className={classNames(
            'flex flex-col gap-[16px]',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
          )}
        >
          <div
            className={classNames(
              'writer-tour-options flex flex-col gap-[16px]',
              isPanelLarge ? 'resizable-sidebar' : 'resizable-sidebar-sm'
            )}
          >
            <div
              className='flex w-full items-center gap-[12px]'
              data-tooltip-id='writer-writeText-keywords'
            >
              <FieldDescription>
                <T keyName='eleo-write-seo-keywords'>SEO Keywords</T>
              </FieldDescription>
              <div
                className={classNames('flex w-full items-center gap-x-2', isPanelLarge && 'flex-1')}
              >
                <Toggle
                  isChecked={isOptimizedForSeoEnabled}
                  setIsChecked={setIsOptimizedForSeoEnabled}
                />
                <Input
                  placeholder={t('eleo-write-seo-keywords-placeholder', 'Your SEO keywords')}
                  className={classNames(
                    'placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]',
                    !isOptimizedForSeoEnabled && 'text-brand-secondary !bg-gray-100'
                  )}
                  disabled={!isOptimizedForSeoEnabled}
                  value={optionsData.keyWords}
                  onChange={setKeyWords}
                />
              </div>
            </div>
            <div className='flex w-full flex-wrap items-center gap-[12px] min-[1400px]:flex-nowrap'>
              <div
                className={classNames('flex w-full  items-center gap-[10px] min-[1400px]:w-1/2')}
                data-tooltip-id='writer-modifyText-style'
              >
                <FieldDescription>
                  <T keyName='eleo-write-text-style'>Style</T>
                </FieldDescription>
                <ReactSelect
                  options={WritingStyleInputData}
                  isClearable
                  clearLabel={t('eleo-clear-button-label-default', 'Default')}
                  // defaultValue={options[0]}
                  placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                  setSelectedValue={handleTopicChange}
                  value={transformSelectOptionValue(
                    WritingStyleInputData,
                    optionsData.state.style,
                    null
                  )}
                  name='STYLE'
                  menuMinWidth={220}
                />
              </div>
              <div
                className='flex w-full items-center gap-[10px] min-[1400px]:w-1/2'
                data-tooltip-id='writer-modifyText-writeAs'
              >
                <FieldDescription>
                  <T keyName='eleo-tool-modify-text-write-new-text-as'>Write new text as:</T>
                </FieldDescription>
                <ReactSelect
                  options={AuthorInputData}
                  isClearable
                  clearLabel={t('eleo-clear-button-label-default', 'Default')}
                  placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                  // defaultValue={options[0]}
                  setSelectedValue={handleTopicChange}
                  value={transformSelectOptionValue(
                    AuthorInputData,
                    optionsData.state.author,
                    null
                  )}
                  name='AUTHOR'
                  menuAlignment='right'
                />
              </div>
            </div>
            <div
              className='flex w-full items-center gap-[12px]'
              data-tooltip-id='writer-modifyText-length'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-length'>Length</T>
              </FieldDescription>
              <WriterLengthContainer
                handleRadioButtonCheck={handleRadioButtonCheck}
                selectedWordLength={wordLength}
                setWriterLengthOption={setWriterLengthOption}
                writerLengthOption={writerLengthOption}
                forceMobile={!isPanelLarge}
              />
            </div>
          </div>
          <div className='flex flex-wrap items-center gap-[12px] min-[1200px]:flex-nowrap'>
            <div
              id='action'
              className='flex w-full   items-center gap-[10px] min-[1200px]:w-1/2'
              data-tooltip-id='writer-modifyText-actions'
            >
              <FieldDescription>
                <T keyName='eleo-tool-modify-text-actions'>Actions</T>
              </FieldDescription>
              <ReactSelect
                options={ActionsInputData}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
                // defaultValue={options[0]}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                value={transformSelectOptionValue(ActionsInputData, optionsData.state.action, null)}
                setSelectedValue={handleTopicChange}
                menuPlacement='top'
                name='ACTION'
              />
            </div>
            <div
              className='flex w-full  items-center gap-[10px] min-[1200px]:w-1/2'
              data-tooltip-id='writer-modifyText-language'
            >
              <FieldDescription>
                <T keyName='eleo-write-text-language'>Language</T>
              </FieldDescription>

              <ReactSelect
                options={languages}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(
                  languages,
                  optionsData.state.language,
                  defaultToolOutPutLanguage
                )}
                name='LANGUAGE'
                menuPlacement='top'
                menuAlignment='right'
              />
            </div>
          </div>
          <div
            className='flex w-full items-center justify-between gap-[10px] min-[1600px]:w-1/2'
            data-tooltip-id='writer-modifyText-creativity'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </FieldDescription>
            <div className='!w-full'>
              <ButtonGroup
                options={Creativity}
                onClick={(event) => setCreativity(event.target.value)}
                value={creativity}
                activeBgClass='bg-[#6363e51a]'
                className='!flex flex-shrink'
              />
            </div>
          </div>
          <div
            className='flex !w-full !flex-nowrap items-center justify-between gap-[10px] min-[1600px]:w-1/2'
            data-tooltip-id='writer-modifyText-tone'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-tone'>Tone</T>
            </FieldDescription>
            <div className='!w-full'>
              <ReactSelect
                options={ToneInputData}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                // defaultValue={options[0]}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(ToneInputData, optionsData.state.tone, null)}
                name='TONE'
                menuPlacement='top'
              />
            </div>
          </div>
          {/* <div className='flex flex-wrap items-center justify-between gap-[12px] '>
            <Guru
              tool='writer-modifyText-guru'
              className='justify-between'
              place='top'
              setIsGuruModeEnabled={setIsGuruModeEnabled}
              isGuruModeEnabled={isGuruModeEnabled}
            />
   
          </div> */}
        </div>
      </div>
    )
  )
}
